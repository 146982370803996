import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICountryCodes } from '../../_models/interface.model.header';

@Component({
  selector: 'rgreen-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() lang: EventEmitter<string> = new EventEmitter<string>();

  public codes: ICountryCodes[];
  public language = new FormControl();

  public ngOnInit(): void {
    this.codes = [
      { value: 'en', text: 'English' },
      { value: 'th', text: 'ภาษาไทย' },
      { value: 'zh', text: '汉语' },
    ];
    const code = localStorage.getItem('locale');
    if (!code) {
      this.language.patchValue('th');
      this.changeLanguage('th');
    } else {
      this.language.patchValue(code);
    }
  }

  public changeLanguage(lang: string): void {
    localStorage.setItem('locale', lang);
    window.location.reload();
  }
}
