<div class="header-grid">
  <div class="header-grid__logo">
    <img routerLink="home" src="assets/img/logo.jpeg" />
  </div>

  <div class="header-grid__language">
    <mat-form-field appearance="outline">
      <mat-icon matPrefix>language</mat-icon>
      <mat-select
        [formControl]="language"
        (selectionChange)="changeLanguage($event.value)"
      >
        <mat-option *ngFor="let code of codes" [value]="code.value">
          {{ code.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
