import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './landing-page/main-page/main-page.component';
import { PropertyDetailsComponent } from './landing-page/property-details/property-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: MainPageComponent,
  },
  {
    path: 'property',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'property',
    children: [
      {
        path: ':id',
        component: PropertyDetailsComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
