<div class="properties-grid">
  <div class="property" *ngFor="let property of properties">
    <img [src]="property.url" [alt]="property.title" class="property__img" />
    <h5 class="property__name">{{ property.title }}</h5>

    <div class="property__rooms">
      <mat-icon aria-hidden="false" aria-label="Example home icon">
        {{ property.icons[0] }}
      </mat-icon>
      <p>{{ property.home }}</p>
    </div>

    <div class="property__price">
      <mat-icon aria-hidden="false" aria-label="Example price icon">
        {{ property.icons[1] }}
      </mat-icon>
      <p>{{ property.price }}</p>
    </div>
    <button
      class="btn property__btn"
      type="button"
      [routerLink]="['/property', property.id]"
    >
      {{ property.button }}
    </button>
  </div>
</div>
