import { Component } from '@angular/core';

@Component({
  selector: 'rgreen-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  public lang: string = localStorage.getItem('locale');
}
