import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMainPageHome,
  IProperties,
  IPropertyDetails,
} from '../_models/interface.model.header';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  readonly translationApi: string = environment.translationApi;

  constructor(private _http: HttpClient) {}

  /**
   * @returns property details based on current language for id
   * @param [id] of the property
   */
  public getPropertyDetailsById(id: string): Observable<IPropertyDetails> {
    return this._http.get<IPropertyDetails>(
      `${this.translationApi}/${localStorage.getItem(
        'locale'
      )}/${id}.property.json`
    );
  }

  public getMainPageHome(): Observable<IMainPageHome> {
    return this._http.get<IMainPageHome>(
      `${this.translationApi}/${localStorage.getItem(
        'locale'
      )}/main_page.home.json`
    );
  }

  public getProperties(): Observable<IProperties> {
    return this._http.get<IProperties>(
      `${this.translationApi}/${localStorage.getItem('locale')}/properties.json`
    );
  }

  public getPropertyDetailsImages(id: string): Observable<string[]> {
    return this._http.get<string[]>(
      `${this.translationApi}/1.property-images/${id}.json`
    );
  }
}
