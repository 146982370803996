import { Component, OnInit } from '@angular/core';
import { IMainPageHome } from '../../_models/interface.model.header';
import { TranslationService } from '../../_services/translation.data.service';

@Component({
  selector: 'rgreen-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public data: IMainPageHome;

  constructor(private _translateSrv: TranslationService) {}

  public ngOnInit(): void {
    this._translateSrv.getMainPageHome().subscribe({
      next: (data) => (this.data = data),
    });
  }

  public scrollToBottom(): void {
    const scrollToOptions: ScrollToOptions = {
      left: 0,
      top: document.body.scrollHeight,
      behavior: 'smooth',
    };
    window.scrollTo(scrollToOptions);
  }
}
