<div class="main-grid" [class]="lang">
  <div class="main-grid__header">
    <rgreen-header></rgreen-header>
  </div>

  <div class="main-grid__property">
    <div class="property-details" *ngIf="propertyDetails">
      <div class="property-details__images">
        <ngx-gallery
          [options]="galleryOptions"
          [images]="galleryImages"
        ></ngx-gallery>
      </div>

      <h1 style="text-align: center">
        {{ propertyDetails["property.details.title"] }}
      </h1>
      <h3 class="image-title">
        {{ propertyDetails["property.details.image_title"] }}
      </h3>
    </div>
  </div>

  <div class="main-grid__content" *ngIf="propertyDetails">
    <div class="info">
      <h3>
        <strong class="text-header">{{
          propertyDetails["property.details.header"]
        }}</strong>
      </h3>

      <p>
        {{ propertyDetails["property.details.property.information.content_1"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_2"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_3"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_4"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_5"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_6"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_7"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_8"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.information.content_9"] }}
      </p>
      <p>
        {{
          propertyDetails["property.details.property.information.content_10"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.property.information.content_11"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.property.information.content_12"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.property.information.content_13"]
        }}
      </p>
    </div>

    <div class="pricing">
      <h3>
        <strong class="text-header">{{
          propertyDetails["property.details.pricing.title_1"]
        }}</strong>
        <h5 style="display: inline-block; color: red; margin-left: 0.2em">
          {{ propertyDetails["property.details.pricing.title_2"] }}
        </h5>
      </h3>

      <p>
        {{ propertyDetails["property.details.pricing.content_1"] }}
      </p>
      <p class="text-align">
        {{ propertyDetails["property.details.pricing.content_2"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.pricing.content_3"] }}
      </p>
      <p class="text-align">
        {{ propertyDetails["property.details.pricing.content_4"] }}
      </p>
      <p class="text-align">
        {{ propertyDetails["property.details.pricing.content_5"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.pricing.content_6"] }}
      </p>
      <p class="text-align">
        {{ propertyDetails["property.details.pricing.content_7"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.pricing.content_8"] }}
      </p>
    </div>

    <div class="amenities">
      <h3>
        <strong class="text-header"
          >{{ propertyDetails["property.details.property.amenities.title"] }}
        </strong>
      </h3>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_1"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_2"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_3"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_4"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_5"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_6"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_7"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_8"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_9"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_10"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_11"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_12"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.property.amenities.content_13"] }}
      </p>
    </div>

    <div class="additional-info">
      <h3>
        <strong class="text-header">{{
          propertyDetails["property.details.additional.information.title"]
        }}</strong>
      </h3>

      <p>
        {{
          propertyDetails["property.details.additional.information.content_1"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.additional.information.content_2"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.additional.information.content_3"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.additional.information.content_4"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.additional.information.content_5"]
        }}
      </p>
      <p>
        {{
          propertyDetails["property.details.additional.information.content_6"]
        }}
      </p>

      <br />
      <p>
        {{ propertyDetails["property.details.check-in"] }}
      </p>
      <p>
        {{ propertyDetails["property.details.check-out"] }}
      </p>
    </div>
  </div>
</div>
