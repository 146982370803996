import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rgreen-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public zoom = 15;
  public center: google.maps.LatLngLiteral;
  public options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  };

  public ngOnInit(): void {
    this.center = {
      lat: 14.602869973006527,
      lng: 101.21879303227605,
    };
  }
}
