import { Component, OnInit } from '@angular/core';
import { IProperties } from '../../_models/interface.model.header';
import { TranslationService } from '../../_services/translation.data.service';

@Component({
  selector: 'rgreen-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent implements OnInit {
  public properties: IProperties;

  constructor(private _translateSrv: TranslationService) {}

  public ngOnInit(): void {
    this._translateSrv.getProperties().subscribe({
      next: (data) => (this.properties = data),
    });
  }
}
