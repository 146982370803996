import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface ITranslationList {
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private _http: HttpClient,
    private _translate: TranslateService
  ) {}

  public init(): Promise<boolean> {
    return new Promise<boolean>((resolve: (res: boolean) => void) => {
      const defaultLocale = 'en';
      const translationsUrl = '/assets/i18n/translations';
      const sufix = '.json';
      const storageLocale = localStorage.getItem('locale');
      const locale = storageLocale || defaultLocale;

      forkJoin([
        this._http
          .get(`/assets/i18n/dev.json`)
          .pipe(catchError(() => of(null))),
        this._http
          .get(`${translationsUrl}/${locale}${sufix}`)
          .pipe(catchError(() => of(null))),
      ]).subscribe((response: ITranslationList[]) => {
        console.log(response);
        const devKeys = response[0];
        const translatedKeys = response[1];

        this._translate.setTranslation(defaultLocale, devKeys || {});
        this._translate.setTranslation(locale, translatedKeys || {}, true);

        this._translate.setDefaultLang(defaultLocale);
        this._translate.use(locale);
        resolve(true);
      });
    });
  }
}
