<div class="footer">
  <div class="footer__qrcode">
    <img src="assets/img/footer/qr_code.jpeg" alt="QR_Code" />
  </div>

  <div class="footer__content">
    <h1 class="title-text">Contact Us</h1>

    <div class="contact-info">
      <div class="contact-info__entry">
        <mat-icon>location_on</mat-icon>
        <p>
          {{ "landing.page.footer.name" | translate }}
          <br />
          <strong> {{ "landing.page.footer.address" | translate }}</strong>
        </p>
      </div>

      <div class="contact-info__entry">
        <mat-icon>phone</mat-icon>
        <p>+66 811036777</p>
      </div>
    </div>
  </div>

  <div class="footer__map">
    <google-map
      width="100%"
      height="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options"
    >
      <map-marker [position]="center"></map-marker>
    </google-map>
  </div>

  <div class="footer__copyright">© Copyright 2021 by Royal Green Valley</div>
</div>
