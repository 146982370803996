import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppInitService } from './app-init.service';
import { LandingPageModule } from './landing-page/landing-page.module';
import { GoogleMapsModule } from '@angular/google-maps';

const loadLanguage =
  (appInitService: AppInitService): (() => Promise<boolean>) =>
  (): Promise<boolean> =>
    appInitService.init();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LandingPageModule,
    GoogleMapsModule,
    TranslateModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadLanguage,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
