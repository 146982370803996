<div class="main-grid" [class]="lang">
  <div class="main-grid__header">
    <rgreen-header></rgreen-header>
  </div>
  <div class="main-grid__home">
    <rgreen-home></rgreen-home>
  </div>
  <div class="main-grid__properties">
    <rgreen-properties></rgreen-properties>
  </div>
  <div class="main-grid__footer">
    <rgreen-footer></rgreen-footer>
  </div>
</div>
