import { Component, OnInit } from '@angular/core';
import { IPropertyDetails } from '../_models/interface.model.header';
import { TranslationService } from '../_services/translation.data.service';

import {
  NgxGalleryAnimation,
  NgxGalleryImage,
  NgxGalleryOptions,
} from 'ngx-gallery-9';
import { Router } from '@angular/router';

@Component({
  selector: 'rgreen-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
})
export class PropertyDetailsComponent implements OnInit {
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[] = [];
  public propertyDetails: IPropertyDetails;
  public imageList: string[];
  public lang: string = localStorage.getItem('locale');
  public id: string;

  constructor(
    private _translateSrv: TranslationService,
    private _route: Router
  ) {}

  public ngOnInit(): void {
    const route: string[] = this._route.url.split('/');
    this.id = route[2];
    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageAutoPlay: true,
        imageArrows: true,
        imageAutoPlayInterval: 5000,
        previewCloseOnClick: true,
        previewKeyboardNavigation: true,
      },
      // max-width 800
      {
        breakpoint: 1000,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
        thumbnailsColumns: 2,
      },
    ];

    this._translateSrv.getPropertyDetailsById(this.id).subscribe({
      next: (data) => (this.propertyDetails = data),
      complete: () =>
        this._translateSrv.getPropertyDetailsImages(this.id).subscribe({
          next: (data) => (this.imageList = data),
          complete: () => {
            for (let i = 0; i < this.imageList.length; i++) {
              this.galleryImages.push({
                small: this.imageList[i],
                medium: this.imageList[i],
                big: this.imageList[i],
              });
            }
          },
        }),
    });
  }
}
