<span class="home">
  <div class="home__pictures">
    <img
      src="assets/img/home_page/home2.jpeg"
      alt="Royal Green Valley Entrance"
      class="home__img--1"
    />
    <!-- <img
      src="assets/img/home_page/home3.jpeg"
      alt="Royal Green Valley Pool"
      class="home__img--2"
    /> -->
  </div>

  <div class="home__content" *ngIf="data">
    <h2 class="heading-2 heading-2--dark mb-md">
      &ldquo;{{ data["main.home.header"] }}&rdquo;
    </h2>
    <div class="home__text">
      <p>{{ data["main.home.content_1"] }}</p>
      <p>{{ data["main.home.content_2"] }}</p>
      <p>{{ data["main.home.content_3"] }}</p>
      <p>{{ data["main.home.content_4"] }}</p>
      <p>{{ data["main.home.content_5"] }}</p>
      <p>{{ data["main.home.content_6"] }}</p>
      <p>{{ data["main.home.content_7"] }}</p>
      <p>{{ data["main.home.content_8"] }}</p>
      <br />
      <p>
        <strong>{{ data["main.home.content_9"] }}</strong>
      </p>
      <p>{{ data["main.home.content_10"] }}</p>
      <p>{{ data["main.home.content_11"] }}</p>
    </div>

    <button class="btn" (click)="scrollToBottom()">Contact Us</button>
  </div>
</span>
